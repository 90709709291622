<script>
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header.vue";
import {paginationHelper} from "@/helpers/pagination-helper";
import axios from "axios";
import {errorCatcher} from "@/helpers/error-catcher";
import Swal from "sweetalert2";
import {StripeCheckout} from "@vue-stripe/vue-stripe";
import {timeUtil} from "@/helpers/time-util";
import {dateUtil} from "../../../helpers/date-util";
import {email, required} from "vuelidate/lib/validators";

export default {

  computed: {
    dateUtil() {
      return dateUtil
    },

    timeUtil() {
      return timeUtil
    },

    paginationHelper() {
      return paginationHelper
    }

  },

  components: {
    StripeCheckout,
    Layout,
    PageHeader
  },

  data() {
    return {

      shop: null,
      industry: null,
      wordPressPlugin: null,

      engines: null,
      markets: null,

      shopProductLimits: null,
      wordpressPlugins: null,

      stripeId: "",

      modals: {

        editElementInTable: {
          item: null,
          title: "",
          visibleModal: false,
          element: "",
          elementName: "",
          type: ""
        },

        productLimit: {

          visible: false

        },

        wordpressPlugin: {

          visible: false,

        }

      },

      shopWithThisDomainAlreadyExists: false,
    };
  },

  validations() {
    if (this.modals.editElementInTable.type === "ENGINE") {
      return {
        modals: {
          editElementInTable: {
            item: {
              domain: {
                required,
                domain: function (value) {
                  return /^[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/.test(value)
                }
              },
              emailAddress: {required, email},
              industryId: { required }
            }
          }
        }
      }
    }

    return {}
  },

  methods: {

    getItems() {
      return [
        {
          text: "ecat"
        },
        {
          text: this.$t('shop-details.title', { 'name': this.shop.name }),
          active: true
        }
      ]
    },

    async loadShop() {
      const id = this.$route.params.id;
      if (!id) {
        await Swal.fire("", "Nie znaleziono takiego sklepu!", "error");
        return;
      }

      try {
        const { data } = await axios.get(`/shop/${id}`, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        this.shop = data
        await this.loadIndustry()
        await this.loadWordPressPlugin()
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async checkDomainIsExists(domain) {
      if (!domain) {
        return
      }

      try {
        const {data} = await axios.get(`/shop/user/exists-by-domain/${domain}`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {}
        });

        const { status } = data
        this.shopWithThisDomainAlreadyExists = status
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async loadWordPressPlugin() {
      if (!this.shop.wordpressPluginMarketplaceOrderId) {
        return
      }

      try {
        const { data } = await axios.get(`/stripe/subscription/order/${this.shop.wordpressPluginMarketplaceOrderId}`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {}
        });

        this.wordPressPlugin = data
      } catch (error) {
        console.log(error)
      }
    },

    async loadIndustry() {
      if (!this.shop.industryId) {
        return
      }

      try {
        const { data } = await axios.get(`/industry/${this.shop.industryId}`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {}
        })

        this.industry = data
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async loadEngines() {
      try {
        const { data } = await axios.get(`/engine`, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        this.engines = new Map(data.map((obj) => [obj.id, obj]));
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    getEngine(id) {
      if (!id || !this.engines) {
        return null
      }

      return this.engines.get(id) || null
    },

    async loadMarkets() {
      try {
        const { data } = await axios.get(`/market`, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        this.markets = new Map(data.map((obj) => [obj.id, obj]));
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    getMarket(id) {
      if (!id || !this.markets) {
        return null
      }

      return this.markets.get(id) || null
    },

    hideEditElementInTableModal() {
      this.modals.editElementInTable.visibleModal = false
      this.modals.editElementInTable.item = null
      this.modals.editElementInTable.title = ""
      this.modals.editElementInTable.element = ""
      this.modals.editElementInTable.elementName = ""
      this.modals.editElementInTable.type = ""
    },

    openEditElementInTableModal(item, element, elementName, type, title) {
      this.modals.editElementInTable.item = Object.assign({}, item)
      this.modals.editElementInTable.element = element
      this.modals.editElementInTable.title = title
      this.modals.editElementInTable.elementName = elementName
      this.modals.editElementInTable.type = type
      this.modals.editElementInTable.visibleModal = true
    },

    stripeSubmit() {
      // You will be redirected to Stripe's secure checkout page
      this.$refs.checkoutRef.redirectToCheckout();
    },

    openProductLimitModal() {
      this.modals.productLimit.visible = true
    },

    hideProductLimitModal() {
      this.modals.productLimit.visible = false
    },

    openWordPressPluginModal() {
      this.modals.wordpressPlugin.visible = true
    },

    hideWordPressPluginModal() {
      this.modals.wordpressPlugin.visible = false
    },

    getCurrentShopProductLimit() {
      if (!this.shopProductLimits) {
        return null
      }

      for (const productVariation of this.shopProductLimits) {
        if (productVariation.value === this.shop.productLimit) {
          return productVariation
        }
      }

      return null
    },

    getCurrentShopWordPressPlugin() {
      if (!this.wordpressPlugins || !this.shop.wordpressPluginMarketplaceOrderId) {
        return null
      }

      for (const productVariation of this.wordpressPlugins) {
        if (productVariation.id === this.shop.wordpressPluginMarketplaceOrderId) {
          return productVariation
        }
      }

      return null
    },

    loadProductVariationByType(productType) {
      axios.get(`/marketplace/product/variation/list`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        data: {},
        params: {
          "type": productType,
        }
      }).then(result => {
        if (productType === 'SHOP_PRODUCT_LIMIT_INCREASE') {
          this.shopProductLimits = result.data
        } else if (productType === 'WORDPRESS_PLUGIN') {
          this.wordpressPlugins = result.data
        }
      }).catch(error => {
        errorCatcher.catchErrors(error)
      });
    },

    createStripeSession(marketplaceOrderId) {
      const json = JSON.stringify({
        "marketplaceOrderId": marketplaceOrderId
      });

      axios.post(`/stripe/session`, json, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
      }).then((result) => {
        if (result.status === 204) {
          window.location = 'testmanager'
          return
        }

        const { data } = result
        this.stripeId = data.stripeId
        this.stripeSubmit()
      }).catch((error) => {
        errorCatcher.catchErrors(error);
      })
    },

    createOrder(marketplaceProductVariationId) {
      const json = JSON.stringify({
        "marketplaceProductVariationId": marketplaceProductVariationId,
        "metadata": JSON.stringify({
          "shopId": this.shop.id
        })
      });

      axios.post(`/marketplace/order`, json, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
      }).then((result) => {
        this.createStripeSession(result.data.marketplaceOrderId)
      }).catch((error) => {
        errorCatcher.catchErrors(error);
      })
    },

    editShop() {
      // if (this.modals.editElementInTable.type === "ENGINE") {
      //   return {
      //     modals: {
      //       editElementInTable: {
      //         item: {
      //           domain: { required }
      //         }
      //       }
      //     }
      //   }
      // }

      this.$v.modals.editElementInTable.item.$touch();
      if (this.$v.modals.editElementInTable.item.$invalid) {
        return;
      }

      const json = JSON.stringify(this.modals.editElementInTable.item);

      axios.put(`/shop`, json, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      }).then(({ data }) => {
        const lastIndustryId = this.shop.industryId
        this.shop = data

        if (lastIndustryId !== data.industryId) {
          this.loadIndustry()
        }

        this.$root.$emit("load-paired-shops")
        Swal.fire("Sukces!", "Zaktualizowano informacje o sklepie", "success");
        this.hideEditElementInTableModal()
      }).catch((error) => {
        errorCatcher.catchErrors(error)
      })
    },

  },

  async created() {
    await this.loadShop()
    await this.loadEngines()
    await this.loadMarkets()
    await this.loadProductVariationByType("SHOP_PRODUCT_LIMIT_INCREASE")
    await this.loadProductVariationByType("WORDPRESS_PLUGIN")
  }

};
</script>

<template>
  <Layout>

    <template v-if="shop && engines">
    <PageHeader :items="getItems()" :title="$t('shop-details.title', { 'name': shop.name })"/>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body" :set="engine = getEngine(shop.engineId)">
              <template v-if="engine && engine.i18nTranslation === 'engine.wordpress'">

                <p>
                Wtyczka WordPress: <b class="badge" :class="$store.getters['shop/isPairedShop'](shop.id) ? 'badge-soft-success' : 'badge-soft-danger'">{{ $store.getters['shop/isPairedShop'](shop.id) ? "Połączono" : "Nie połączono"}}</b>
                </p>

                <p>Licencja WordPress:
                  <b v-if="wordPressPlugin" class="badge" :class="wordPressPlugin.internalStatus === 'ACTIVE' ? 'badge-soft-success' : 'badge-soft-danger'">{{  wordPressPlugin.internalStatus === 'ACTIVE' ? ("Aktywna") : "Nieaktywna" }}</b>
                  <b v-else class="badge badge-soft-danger">Nieaktywna</b>

                  <template v-if="wordPressPlugin && wordPressPlugin.internalStatus === 'ACTIVE'">
                    <b class="mx-2">
                      <button id="wordpress-license" class="btn btn-sm btn-outline-warning">
                        <i class="ri-information-line align-middle"></i>
                      </button>

                      <b-tooltip target="wordpress-license" placement="left">Licencja aktywna do: {{ dateUtil.asDate(wordPressPlugin.currentPeriodEnd) }}</b-tooltip>
                    </b>
                  </template>

                  <button class="btn btn-sm btn-primary mx-2" @click="openWordPressPluginModal">
                    <i class="fa fa-shopping-cart"></i>
                  </button>
                </p>
              </template>

              <p>
                Nazwa sklepu: <b>{{ shop.name }}</b>

                <button class="btn btn-sm btn-success mx-2" @click="openEditElementInTableModal(shop, 'name', 'Nazwa sklepu', 'STRING', 'Zmiana nazwy sklepu')">
                  <i class="mdi mdi-pencil"></i>
                </button>
              </p>

              <template v-if="engine">
                <p v-if="engine.i18nTranslation === 'engine.wordpress'">
                  Domena: <b><a :href="`https://${shop.domain}`">{{ shop.domain }}</a></b>

                  <button class="btn btn-sm btn-success mx-2" @click="openEditElementInTableModal(shop, 'domain', 'Domena', 'STRING', 'Zmiana domeny sklepu')">
                    <i class="mdi mdi-pencil"></i>
                  </button>
                </p>
              </template>

              <p>
                Limit produktów: <b>{{ shop.productLimit }}</b>

                <button class="btn btn-sm btn-primary mx-2" @click="openProductLimitModal">
                  <i class="fa fa-shopping-cart"></i>
                </button>
              </p>

              <p v-if="engine && engine.i18nTranslation !== 'engine.virtual'">
                Branża sklepu: <b>{{ industry ? industry.name : "..." }}</b>

                <button class="btn btn-sm btn-success mx-2" @click="openEditElementInTableModal(shop, 'industryId', 'Branża sklepu', 'INDUSTRY', 'Zmiana branży sklepu')">
                  <i class="mdi mdi-pencil"></i>
                </button>
              </p>

              <template v-if="engine">
                <p>
                  Silnik sklepu: <b>{{ $t(engine.i18nTranslation) }}</b>

                  <button class="btn btn-sm btn-success mx-2" @click="openEditElementInTableModal(shop, 'engineId', 'Silnik sklepu', 'ENGINE', 'Zmiana silnika sklepu')">
                    <i class="mdi mdi-pencil"></i>
                  </button>

                  <template v-if="engine.i18nTranslation === 'engine.wordpress'">
                    <a id="wordpress-plugin" href="/ECAT-ECOMMERCE.zip" class="btn btn-sm btn-primary">
                      <i class="ri-plug-fill align-middle"></i>
                    </a>

                    <b-tooltip target="wordpress-plugin" placement="left">Pobierz wtyczkę</b-tooltip>
                  </template>
                </p>
              </template>

<!--              <p>-->
<!--                Logo sklepu w StrefieBezpiecznychZakupów:-->

<!--                <b>TODO</b>-->
<!--              </p>-->

              <p v-if="engine && engine.i18nTranslation === 'engine.wordpress'">
                Dodatkowe integracje

                <button class="btn btn-sm btn-success mx-2" @click="openEditElementInTableModal(shop, null, null, 'INTEGRATIONS', 'Integracje')">
                  <i class="mdi mdi-pencil"></i>
                </button>
              </p>

              <p v-if="engine && engine.i18nTranslation === 'engine.wordpress'">
                Adres E-mail sklepu: <b>{{ shop.emailAddress }}</b>

                <button class="btn btn-sm btn-success mx-2" @click="openEditElementInTableModal(shop, 'emailAddress', 'Adres E-mail sklepu', 'STRING', 'Zmiana adresu E-mail sklepu')">
                  <i class="mdi mdi-pencil"></i>
                </button>
              </p>

<!--              <p>-->
<!--                Osoba wspierająca:-->

<!--                <b>TODO</b>-->
<!--              </p>-->

              <div :set="market = getMarket(shop.marketId)">
                <p v-if="market">
                  Rynek sklepu:

                  <b>{{ $t(market.i18nTranslation) }}</b>
                </p>
              </div>

              <div class="button-items">
                <button @click="openEditElementInTableModal(shop, null, null, 'INTEGRATIONS', 'Analityka')" class="btn btn-outline-primary mb-2" v-if="engine && engine.i18nTranslation !== 'engine.virtual'">
                  Analityka
                </button>

                <a :href="`/dashboard/user/shop/products?shopId=${this.shop.id}`" class="btn btn-outline-success mb-2">
                  Produkty w sklepie
                </a>

                <template v-if="engine && engine.i18nTranslation === 'engine.wordpress'">
                  <a :href="`/dashboard/shop/categories?shopId=${this.shop.id}`" class="btn btn-outline-warning mb-2">
                    Kategorie sklepu
                  </a>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="text-center py-3">
        <h5>{{ $t('message.loading') }}</h5>
        <span aria-hidden="true" class="m-2 spinner-border text-primary"></span>
      </div>
    </template>

    <b-modal
        id="modal-1"
        v-model="modals.editElementInTable.visibleModal"
        hide-footer
        :title="`${modals.editElementInTable.title}`"
        title-class="font-18"
        @esc="hideEditElementInTableModal"
        @hide="hideEditElementInTableModal">

      <label v-if="modals.editElementInTable.elementName">{{ modals.editElementInTable.elementName }}</label>
      <template v-if="modals.editElementInTable.type === 'INT'">
        <input v-model.number="modals.editElementInTable.item[modals.editElementInTable.element]" type="number" class="form-control" />
      </template>
      <template v-else-if="modals.editElementInTable.type === 'STRING'">
        <input v-model="modals.editElementInTable.item[modals.editElementInTable.element]" type="text" class="form-control" />
      </template>
      <template v-else-if="modals.editElementInTable.type === 'INDUSTRY'">
        <ecat-multiselect @change="value => modals.editElementInTable.item[modals.editElementInTable.element] = value"
                          :can-unselect="true"
                          save-id="id"
                          view-id="name"
                          placeholder="Wybierz branże"
                          load-url="/industry/list/pagination"
                          query-url="/industry/by-name"
                          list-name="industries"
                          param="name">
        </ecat-multiselect>
      </template>
      <template v-else-if="modals.editElementInTable.type === 'ENGINE'">
        <select v-model="modals.editElementInTable.item[modals.editElementInTable.element]" class="custom-select">
          <option v-for="[key, value] in engines" :key="key" :value="key" >{{ $t(value.i18nTranslation) }}</option>
        </select>

        <template v-if="$store.getters['engine/getEngineName'](modals.editElementInTable.item.engineId) === 'engine.wordpress'">
          <div class="form-group">
            <label for="domain">{{  $t('message.domain') }}</label>
            <input
                id="domain"
                v-model="modals.editElementInTable.item.domain"
                @input="event => checkDomainIsExists(event.target.value)"
                type="text"
                class="form-control"
                :class="{ 'is-invalid': ($v.modals.editElementInTable.item.domain.$error) || shopWithThisDomainAlreadyExists }"
            />
            <div v-if="!$v.modals.editElementInTable.item.domain.required" class="invalid-feedback">{{ $t('message.required')}}</div>
            <div v-if="!$v.modals.editElementInTable.item.domain.domain" class="invalid-feedback">{{ $t('user-shops.create-shop.is-domain')}}</div>
            <div v-if="shopWithThisDomainAlreadyExists" class="invalid-feedback">Taka domena jest już używana</div>
          </div>

          <div class="form-group">
            <label for="email">{{  $t('message.shop-email') }}</label>
            <input
                id="email"
                v-model="modals.editElementInTable.item.emailAddress"
                type="email"
                name="email"
                class="form-control"
                :class="{ 'is-invalid': $v.modals.editElementInTable.item.emailAddress.$error }"
            />
            <div v-if="!$v.modals.editElementInTable.item.emailAddress.required" class="invalid-feedback">{{ $t('message.required')}}</div>
            <div v-if="!$v.modals.editElementInTable.item.emailAddress.email" class="invalid-feedback">{{ $t('message.required')}}</div>
          </div>

          <div v-if="$store.getters['engine/getEngineName'](modals.editElementInTable.item.engineId) !== 'engine.virtual'" class="form-group">
            <ecat-multiselect
                @change="value => modals.editElementInTable.item.industryId = value"
                save-id="id"
                view-id="name"
                :label="$t('message.industry')"
                placeholder="Wybierz branże"
                fetch-one-url="/industry"
                load-url="/industry/list/pagination"
                query-url="/industry/by-name"
                list-name="industries"
                :set-value="modals.editElementInTable.item.industryId"
                param="name"
                :class="{ 'is-invalid': $v.modals.editElementInTable.item.industryId.$error }"
            />
            <div v-if="!$v.modals.editElementInTable.item.industryId.required" class="invalid-feedback">{{ $t('message.required')}}</div>
          </div>
        </template>
      </template>

      <template v-else-if="modals.editElementInTable.type === 'INTEGRATIONS'">
        <div class="form-group">
          <label for="google_analytics">Google Analytics</label>
          <input
              id="google_analytics"
              v-model="modals.editElementInTable.item.googleAnalytics"
              type="text"
              name="google_analytics"
              class="form-control" />
        </div>

        <div class="form-group">
          <label for="google_tag_manager">Google Tag Manager</label>
          <input
              id="google_tag_manager"
              v-model="modals.editElementInTable.item.googleTagManager"
              type="text"
              name="google_tag_manager"
              class="form-control" />
        </div>

        <div class="form-group">
          <label for="facebook_pixel">{{  $t('user-shops.facebook-pixel') }}</label>
          <input
              id="facebook_pixel"
              v-model="modals.editElementInTable.item.facebookPixel"
              type="text"
              name="facebook_pixel"
              class="form-control" />
        </div>
      </template>

      <div class="text-center">
        <b-button class="mx-2" variant="success" @click="editShop">
          Zapisz
        </b-button>

        <b-button variant="danger" class="my-3" @click="hideEditElementInTableModal">
          {{ $t('message.cancel') }}
        </b-button>
      </div>
    </b-modal>

    <b-modal
        class="multistore-modal"
        modal-class="multistore-modal"
        id="modal-2"
        v-model="modals.productLimit.visible"
        title="Zwiększenie limitu produktów na sklepie"
        title-class="font-18"
        hide-footer
        @hide="hideProductLimitModal"
        @esc="hideProductLimitModal">

      <div class="row pricing-card">
        <div class="col-12 col-sm-offset-2 col-sm-10 col-md-12 col-lg-offset-2 col-lg-10 mx-auto">
          <div class="row" :set="shopProductLimit = getCurrentShopProductLimit()">

            <div class="col-12 col-md-3" v-for="(productLimit, index) in shopProductLimits" :key="index">
              <div class="card basic-pricing popular text-center">
                <div class="card-body" :class="`${shopProductLimit && shopProductLimit.id === productLimit.id ? 'current-pricing-card' : 'selected-pricing-card'}`">
                  <h3>{{  productLimit.title }}</h3>
                  <div class="annual-plan">
                    <div class="plan-price mt-2">
                      <sup class="font-medium-1 fw-bold text-primary">$</sup>
                      <span class="pricing-enterprise-value fw-bolder text-primary">{{  productLimit.priceWithTax.toFixed(2) }}</span>
                      <sub class="pricing-duration text-body font-medium-1 fw-bold">/{{ timeUtil.getHumanDay(productLimit.subscriptionTimeUnit, true) }}</sub>
                    </div>
                    <small class="annual-pricing d-none text-muted"></small>
                  </div>
                  <ul class="list-group list-group-circle text-start">
                    <template v-if="productLimit.description">
                      <template v-if="productLimit.description.includes('\n')">
                        <li class="list-group-item" v-for="(description, index) in productLimit.description.split('\n')" :key="index">{{ description }}</li>
                      </template>
                      <template v-else>
                        {{ productLimit.description }}
                      </template>
                    </template>
                  </ul>
                  <button id="enterprise_pricing_popular" class="btn w-100 mt-2 waves-effect" :class="`${shopProductLimit && shopProductLimit.id === productLimit.id ? 'btn-outline-success' : 'btn-outline-primary'}`" @click="createOrder(productLimit.id)" :disabled="shopProductLimit && shopProductLimit.id === productLimit.id">
                    <template v-if="shopProductLimit && shopProductLimit.id === productLimit.id">
                      Obecny plan
                    </template>
                    <template v-else>
                      Wybierz plan
                    </template>
                  </button>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </b-modal>

    <b-modal
        class="multistore-modal"
        modal-class="multistore-modal"
        id="modal-3"
        v-model="modals.wordpressPlugin.visible"
        title="Wtyczka WordPress"
        title-class="font-18"
        hide-footer
        @hide="hideWordPressPluginModal"
        @esc="hideWordPressPluginModal">

      <div class="row pricing-card">
        <div class="col-12 col-sm-offset-2 col-sm-10 col-md-12 col-lg-offset-2 col-lg-10 mx-auto">
          <div class="row" :set="currentWordpressPlugin = getCurrentShopWordPressPlugin()">

            <div class="col-12 col-md-3" v-for="(wordpressPlugin, index) in wordpressPlugins" :key="index">
              <div class="card basic-pricing popular text-center">
                <div class="card-body" :class="`${currentWordpressPlugin && currentWordpressPlugin.id === wordpressPlugin.id ? 'current-pricing-card' : 'selected-pricing-card'}`">
                  <h3>{{  wordpressPlugin.title }}</h3>
                  <!--                  <p class="card-text">{{  productLimit.title }}</p>-->
                  <div class="annual-plan">
                    <div class="plan-price mt-2">
                      <sup class="font-medium-1 fw-bold text-primary">$</sup>
                      <span class="pricing-enterprise-value fw-bolder text-primary">{{  wordpressPlugin.priceWithTax.toFixed(2) }}</span>
                      <sub class="pricing-duration text-body font-medium-1 fw-bold">/{{ timeUtil.getHumanDay(wordpressPlugin.subscriptionTimeUnit, true) }}</sub>
                    </div>
                    <small class="annual-pricing d-none text-muted"></small>
                  </div>
                  <ul class="list-group list-group-circle text-start">
                    <template v-if="wordpressPlugin.description">
                      <template v-if="wordpressPlugin.description.includes('\n')">
                        <li class="list-group-item" v-for="(description, index) in wordpressPlugin.description.split('\n')" :key="index">{{ description }}</li>
                      </template>
                      <template v-else>
                        {{ wordpressPlugin.description }}
                      </template>
                    </template>
                  </ul>
                  <button id="enterprise_pricing_popular" class="btn w-100 mt-2 waves-effect" :class="`${currentWordpressPlugin && currentWordpressPlugin.id === wordpressPlugin.id ? 'btn-outline-success' : 'btn-outline-primary'}`" @click="createOrder(wordpressPlugin.id)" :disabled="currentWordpressPlugin && currentWordpressPlugin.id === wordpressPlugin.id">
                    <template v-if="currentWordpressPlugin && currentWordpressPlugin.id === wordpressPlugin.id">
                      Obecny plan
                    </template>
                    <template v-else>
                      Wybierz plan
                    </template>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    <stripe-checkout
        ref="checkoutRef"
        v-if="$store.getters['stripe/publishableKey']"
        :pk="$store.getters['stripe/publishableKey']"
        :session-id="stripeId"
    />

  </Layout>
</template>